.LandingView {
  text-align: center;
}

.LandingView h4 {
  margin-top: 1vh;
  color: rgb(224, 223, 223);
  font-size: min(3vw, 20px);
}

.LandingView h1 {
  color: rgb(51, 51, 51);
  margin-top: 24vh;
  font-size: min(5vw, 180px);
  margin-bottom: 1vh;
}

.LandingView p {
  margin-top: 10vh;
  margin-bottom: 0px;
}

.LandingView .GridContainer {
  margin: 0px;
  padding: 0px;
}
